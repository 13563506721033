import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="home-counters"
export default class extends Controller {
  static targets = [ "iframe", "iframeModal" ]

  setIframeSrc(event) {
    const modal_id = event.target.closest('button').dataset.bsTarget.replace('#','');
    const modal = this.iframeModalTargets.find(modal => modal.id == modal_id);
    this.iframeTargets.forEach(iframe => {
      if (modal.contains(iframe) && iframe.src == '') { this.iframeTarget.src = this.iframeDataUrl(iframe); }
    })
  }

  iframeDataUrl(iframe) {
    return iframe.dataset.url;
  }
}
