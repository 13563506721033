import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["icon"]

  connect() {
    const theme = this.storageTheme() || this.preferredTheme();
    if (theme) this.setTheme(theme);
  }

  toggleTheme() {
    const theme = this.newThemeValue();
    this.setTheme(theme);
  }

  updateIcon(theme) {
    const iconElement = this.iconTarget;
    const darkIcon = iconElement.getAttribute('data-dark-icon');
    const lightIcon = iconElement.getAttribute('data-light-icon');

    if (theme === 'dark') {
      iconElement.classList.remove(lightIcon);
      iconElement.classList.add(darkIcon);
    } else {
      iconElement.classList.remove(darkIcon);
      iconElement.classList.add(lightIcon);
    }
  }

  newThemeValue() {
    return this.currentThemeValue() === 'light' ? 'dark' : 'light';
  }

  currentThemeValue() {
    return document.body.dataset.bsTheme || 'light';
  }

  preferredTheme() {
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  }

  storageTheme() {
    return localStorage.getItem('theme');
  }

  setStoredTheme(theme) {
    localStorage.setItem('theme', theme);
  }

  setTheme(theme) {
    document.body.setAttribute('data-bs-theme', theme);
    this.setStoredTheme(theme);
    this.updateIcon(theme);
  }
}
