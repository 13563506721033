import { application } from "./controllers/application"
import HomeCountersController from "./controllers/home_counters_controller"
application.register("home-counters", HomeCountersController)

import ThemeController from "./controllers/theme_controller"
application.register("theme", ThemeController)

import * as bootstrap from "bootstrap"

document.addEventListener('DOMContentLoaded', () => {
  // Shrink the navbar
  navbarShrink();
  // Shrink the navbar when page is scrolled
  document.addEventListener('scroll', navbarShrink);

  // Collapse responsive navbar when toggler is visible
  collapseNavbarMenu();

  animateCounters('.counter.animated', 'animation-speed');
});

function animateCounters(selector, speedAttribute) {
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const counter = entry.target;
        const target = +counter.innerHTML;
        let count = 0;
        const inverse_speed = parseInt(counter.getAttribute(speedAttribute)) || 100; // Decreasing this value will make the animation faster
        const inc = Math.ceil(target / inverse_speed);

        const update = () => {
          if (count < target) {
            count = Math.min(count + inc, target);
            counter.innerHTML = count;
            requestAnimationFrame(update);
          }
        };

        update();

        observer.unobserve(counter); // Stop observing the counter once it's animated
      }
    });
  });

  document.querySelectorAll(selector).forEach(counter => {
    observer.observe(counter);
  });
}

function navbarShrink() {
  const navbarCollapsible = document.querySelector('#mainNav');
  if (navbarCollapsible) {
    navbarCollapsible.classList.toggle('navbar-shrink', window.scrollY !== 0);
  }
}

function collapseNavbarMenu() {
  const navbarToggler = document.querySelector('.navbar-toggler');
  const responsiveNavItems = Array.from(document.querySelectorAll('#navbarResponsive .nav-link'));
  responsiveNavItems.forEach(responsiveNavItem => {
    responsiveNavItem.addEventListener('click', () => {
      if (window.getComputedStyle(navbarToggler).display !== 'none') {
        navbarToggler.click();
      }
    });
  });
}
